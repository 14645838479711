import { createAction, props } from '@ngrx/store';
import { UserScopeViewModel } from '../../main/viewModels/userScope.viewModel';

export const LOAD_USER_SCOPES = '[USER_SCOPES] LOAD_USER_SCOPES';
export const LOAD_USER_SCOPES_SUCCESS = '[USER_SCOPES] LOAD_USER_SCOPES_SUCCESS';
export const LOAD_USER_SCOPES_ERROR = '[USER_SCOPES] LOAD_USER_SCOPES_ERROR';

export const loadUserScopesAction = createAction(LOAD_USER_SCOPES);
export const loadUserScopesSuccessAction = createAction(
  LOAD_USER_SCOPES_SUCCESS,
  props<{ payload: { userScopes: UserScopeViewModel[] } }>()
);
export const loadUserScopesErrorAction = createAction(LOAD_USER_SCOPES_ERROR, props<{ payload: { error: string } }>());

export const SET_APPLIED_USER_SCOPE = '[USER_SCOPES] SET_APPLIED_USER_SCOPE';

export const setAppliedUserScopeAction = createAction(
  SET_APPLIED_USER_SCOPE,
  props<{ payload: { appliedUserScope: UserScopeViewModel } }>()
);

export const SET_ARTICLE_USER_SCOPES = '[ARTICLES] SET_ARTICLE_USER_SCOPES';

export const setArticleUserScopes = createAction(
  SET_ARTICLE_USER_SCOPES,
  props<{ payload: { articleUserScopes: UserScopeViewModel[] } }>()
);
