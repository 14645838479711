import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthFacade } from '../../store/facades/auth.facade';
import { NotificationsService } from '../services/notifications.service';

@Injectable()
export class ExceptionInterceptor implements HttpInterceptor {
  constructor(
    private readonly notificationsService: NotificationsService,
    private readonly authFacade: AuthFacade,
    private readonly router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        this.notificationsService.error(err.error.message);

        if (err.status === 401) {
          this.authFacade.logout();
          this.router.navigate(['/auth']);
        }

        throw err;
      })
    );
  }
}
