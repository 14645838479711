import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthFacade } from '../../../store/facades/auth.facade';
import { isChildOf } from '../../utilities/html.utilities';
import { UserViewModel } from '../../viewModels/user.viewModel';

@Component({
  selector: 'app-profile-section',
  templateUrl: './profile-section.component.html',
  styleUrls: ['./profile-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSectionComponent implements OnInit {
  @ViewChild('profileMenu') profileMenu: ElementRef<HTMLElement>;

  user$: Observable<UserViewModel>;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly authFacade: AuthFacade,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.user$ = this.authFacade.user$;
  }

  getUserName(user: UserViewModel): string {
    return `${user.firstname} ${user.lastname}`;
  }

  @HostListener('click', ['$event'])
  onHostClick(): void {
    this.elementRef.nativeElement.classList.toggle('open');
    this.profileMenu.nativeElement.classList.toggle('visible');
  }

  @HostListener('window:click', ['$event'])
  onWindowClick(e: Event): void {
    const target: HTMLElement = e.target as HTMLElement;

    if (!isChildOf(this.elementRef.nativeElement, target)) {
      this.elementRef.nativeElement.classList.remove('open');
      this.profileMenu.nativeElement.classList.remove('visible');
    }
  }

  onLogout(): void {
    this.authFacade.logout();
    this.router.navigate(['/auth']);
  }
}
