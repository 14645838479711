import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DynamicComponentsService {
  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector
  ) {}

  createComponentElement<T>(componentType: Type<T>, obj: object): HTMLElement {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const component: ComponentRef<T> = componentFactory.create(this.injector);

    Object.keys(obj).forEach((k) => (component.instance[k] = obj[k]));

    component.changeDetectorRef.detectChanges();

    return component.location.nativeElement;
  }
}
