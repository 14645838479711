import { createAction, props } from '@ngrx/store';
import { ArticleViewModel } from '../../main/viewModels/article.viewModel';

export const LOAD_ARTICLES = '[ARTICLES] LOAD_ARTICLES';
export const LOAD_ARTICLES_SUCCESS = '[ARTICLES] LOAD_ARTICLES_SUCCESS';
export const LOAD_ARTICLES_ERROR = '[ARTICLES] LOAD_ARTICLES_ERROR';

export const loadArticlesAction = createAction(
  LOAD_ARTICLES,
  props<{ payload: { scopeIds: number[]; searchTerm: string } }>()
);
export const loadArticlesSuccessAction = createAction(
  LOAD_ARTICLES_SUCCESS,
  props<{ payload: { articles: ArticleViewModel[] } }>()
);
export const loadArticlesErrorAction = createAction(LOAD_ARTICLES_ERROR, props<{ payload: { error: string } }>());

export const SET_ARTICLES_SEARCH_TERM = '[ARTICLES] SET_ARTICLES_SEARCH_TERM';

export const setArticlesSearchTermAction = createAction(
  SET_ARTICLES_SEARCH_TERM,
  props<{ payload: { articlesSearchTerm: string } }>()
);
