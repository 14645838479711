import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingComponent } from './components/loading/loading.component';
import { PillSwitchComponent } from './components/pill-switch/pill-switch.component';
import { ProfileSectionComponent } from './components/profile-section/profile-section.component';
import { TabComponent } from './components/tabset/tab/tab.component';
import { TabsetComponent } from './components/tabset/tabset.component';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { InputValidationDirective } from './directives/input-validation.directive';
import { TabHeadingDirective } from './directives/tab-heading.directive';

const MODULES = [CommonModule, MatProgressSpinnerModule, FormsModule];

const COMPONENTS = [
  ProfileSectionComponent,
  TabsetComponent,
  TabComponent,
  ValidationErrorComponent,
  LoadingComponent,
  PillSwitchComponent,
];

const DIRECTIVES = [TabHeadingDirective, InputValidationDirective];

const PIPES = [];

const DECLARATIONS = [...COMPONENTS, ...DIRECTIVES, ...PIPES];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class SharedModule {}
