import { ActionReducerMap, createSelector } from '@ngrx/store';
import * as fromArticles from './articles.reducers';
import * as fromAuth from './auth.reducers';
import * as fromUserScopes from './userScopes.reducers';

export interface State {
  auth: fromAuth.AuthState;
  articles: fromArticles.ArticlesState;
  userScopes: fromUserScopes.UserScopesState;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.AuthReducer,
  articles: fromArticles.ArticlesReducer,
  userScopes: fromUserScopes.UserScopesReducer,
};

const getAuthState = (state: State) => state.auth;

export const getToken = createSelector(getAuthState, fromAuth.getToken);
export const getUser = createSelector(getAuthState, fromAuth.getUser);
export const getUserScope = createSelector(getAuthState, fromAuth.getUserScope);

const getArticlesState = (state: State) => state.articles;

export const getArticles = createSelector(getArticlesState, fromArticles.getArticles);

export const getArticlesSearchTerm = createSelector(getArticlesState, fromArticles.getArticlesSearchTerm);

const getUserScopesState = (state: State) => state.userScopes;

export const getUserScopes = createSelector(getUserScopesState, fromUserScopes.getUserScopes);

export const getAppliedUserScope = createSelector(getUserScopesState, fromUserScopes.getAppliedUserScope);

export const getArticleUserScopes = createSelector(getUserScopesState, fromUserScopes.getArticleUserScopes);
