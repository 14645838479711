import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserScopeViewModel } from '../viewModels/userScope.viewModel';

@Injectable({
  providedIn: 'root',
})
export class UserScopesService {
  constructor(private readonly httpClient: HttpClient) {}

  getUserScopes(): Observable<UserScopeViewModel[]> {
    return this.httpClient.get<UserScopeViewModel[]>(`/user-scopes`);
  }
}
