import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TabHeadingDirective } from '../../../directives/tab-heading.directive';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnInit {
  @ViewChild('tabContent', { static: true }) tabContent: TemplateRef<ElementRef<HTMLElement>>;
  @ContentChild(TabHeadingDirective, { static: true })
  tabHeadingDirective: TabHeadingDirective;

  active: boolean;

  ngOnInit(): void {}
}
