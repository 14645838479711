import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { ArticlesService } from '../../main/services/articles.service';
import { ArticleViewModel } from '../../main/viewModels/article.viewModel';
import * as ArticlesActions from '../actions/articles.actions';

@Injectable({
  providedIn: 'root',
})
export class ArticlesEffects {
  loadArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ArticlesActions.loadArticlesAction),
      concatMap(({ payload: { scopeIds, searchTerm } }) =>
        this.articlesService.getArticles(scopeIds, searchTerm).pipe(
          map((articles: ArticleViewModel[]) => {
            return ArticlesActions.loadArticlesSuccessAction({
              payload: { articles },
            });
          }),
          catchError((error: Error) =>
            of(
              ArticlesActions.loadArticlesErrorAction({
                payload: { error: error.message },
              })
            )
          )
        )
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly articlesService: ArticlesService) {}
}
