import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthFacade } from './store/facades/auth.facade';
import { UserScopesFacade } from './store/facades/userScopes.facade';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  loading: boolean = true;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly userScopesFacade: UserScopesFacade,
    private readonly authFacade: AuthFacade
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.authFacade.token$.pipe(
        tap((token: string) => {
          if (token) {
            this.userScopesFacade.loadUserScopes();
          }
        })
      ),
      this.userScopesFacade.userScopes$,
    ]).subscribe(([token, ...responses]) => {
      if (token) {
        this.loading = !responses.every(Boolean);
      } else {
        this.loading = false;
      }

      this.changeDetectorRef.markForCheck();
    });
  }
}
