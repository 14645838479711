import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabset',
  templateUrl: './tabset.component.html',
  styleUrls: ['./tabset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsetComponent implements OnInit {
  @ContentChildren(TabComponent, { static: true } as unknown)
  tabComponents: QueryList<TabComponent>;

  @Input() tabIndex: number;

  @Output() tabChanged: EventEmitter<TabComponent> = new EventEmitter();
  @Output() tabIndexChanged: EventEmitter<number> = new EventEmitter();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.tabComponents.first.active = true;
  }

  ngOnChanges() {
    if (this.tabIndex !== undefined) {
      this.tabs.filter((_, i) => i !== this.tabIndex).forEach((t) => (t.active = false));
      this.tabs[this.tabIndex].active = true;
    } else {
      this.tabComponents.first.active = true;
    }

    this.changeDetectorRef.detectChanges();
  }

  get tabs(): TabComponent[] {
    return this.tabComponents.toArray();
  }

  get activeTab(): TabComponent {
    return this.tabs.find((t) => t.active);
  }

  get activeTabContent(): TemplateRef<ElementRef<HTMLElement>> {
    return this.activeTab.tabContent;
  }

  onTabClicked(tab: TabComponent, index: number): void {
    this.tabs.filter((_, i) => i !== index).forEach((t) => (t.active = false));

    tab.active = true;

    this.changeDetectorRef.detectChanges();
    this.tabChanged.emit(tab);
    this.tabIndexChanged.emit(index);
  }
}
