import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthFacade } from '../../store/facades/auth.facade';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private readonly authFacade: AuthFacade) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authFacade.token$.pipe(
      take(1),
      switchMap((token: string) =>
        next.handle(
          req.clone({
            headers: new HttpHeaders().append('Authorization', `Bearer ${token}`),
          })
        )
      )
    );
  }
}
