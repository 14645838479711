<ng-container *ngIf="user$ | async as user">
  <div class="info">
    <h5>{{ getUserName(user) }}</h5>
    <p>{{ user.email }}</p>
  </div>

  <div class="picture">
    <div class="icon"></div>
  </div>
</ng-container>

<div #profileMenu class="profile-menu">
  <div class="menu-item" (click)="onLogout()">Logout</div>
</div>
