import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { LoginResponseDTO } from '../../auth/dto/loginResponse.dto';
import { AuthService } from '../../auth/services/auth.service';
import * as AuthActions from '../actions/auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginAction),
      concatMap(({ payload }) =>
        this.authService.login(payload).pipe(
          map((loginResponseDTO: LoginResponseDTO) => {
            return AuthActions.loginSuccessAction({
              payload: loginResponseDTO,
            });
          }),
          catchError((error: Error) =>
            of(
              AuthActions.loginErrorAction({
                payload: { error: error.message },
              })
            )
          )
        )
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly authService: AuthService) {}
}
