import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { ValidationErrorComponent } from '../components/validation-error/validation-error.component';
import { IInputValidation } from '../interfaces/inputValidation.interface';
import { DynamicComponentsService } from '../services/dynamic-components.service';

@Directive({
  selector: '[appInputValidation]',
})
export class InputValidationDirective implements OnChanges {
  @Input() inputValidation: IInputValidation;

  validationErrorElement: HTMLElement;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly dynamicComponentsManager: DynamicComponentsService
  ) {}

  ngOnChanges() {
    const { condition, error } = this.inputValidation;

    this.toggleErrorClass(condition);
    this.toggleError(condition, error);
  }

  private toggleErrorClass(condition: boolean) {
    if (!condition) {
      this.elementRef.nativeElement.classList.remove('invalid');
    } else {
      this.elementRef.nativeElement.classList.add('invalid');
    }
  }

  private toggleError(condition: boolean, error: string) {
    if (!condition) {
      this.destroyValidationError();
    } else {
      this.destroyValidationError();

      this.validationErrorElement = this.dynamicComponentsManager.createComponentElement(ValidationErrorComponent, {
        error,
      });

      this.elementRef.nativeElement.insertAdjacentElement('afterend', this.validationErrorElement);
    }
  }

  private destroyValidationError() {
    if (this.validationErrorElement) {
      this.validationErrorElement.remove();
    }
  }
}
