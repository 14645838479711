import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthFacade } from '../../store/facades/auth.facade';

@Injectable({
  providedIn: 'root',
})
export class UnauthGuard implements CanActivate {
  constructor(private readonly authFacade: AuthFacade, private readonly router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authFacade.token$.pipe(
      take(1),
      map((token: string) => {
        const tokenDoesNotExist: boolean = !Boolean(token);

        if (!tokenDoesNotExist) {
          this.router.navigate(['/']);
        }

        return tokenDoesNotExist;
      })
    );
  }
}
