<div class="tabset-tabs">
  <div
    class="tabset-tab"
    *ngFor="let tab of tabs; let i = index"
    (click)="onTabClicked(tab, i)"
    [class.tabset-tab-active]="tab.active"
    matRipple
  >
    <ng-template [ngTemplateOutlet]="tab.tabHeadingDirective.templateRef"></ng-template>
    <div *ngIf="tab.active" class="tabset-tab-active-indicator">
      <div class="tabset-tab-active-indicator-circle"></div>
    </div>
  </div>
</div>

<ng-template [ngTemplateOutlet]="activeTabContent"></ng-template>
