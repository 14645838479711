import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-pill-switch',
  templateUrl: './pill-switch.component.html',
  styleUrls: ['./pill-switch.component.scss'],
})
export class PillSwitchComponent implements OnInit, AfterViewInit {
  @Input() left!: string;
  @Input() right!: string;
  @Input() activeIndex!: number;

  @Output() activeIndexChanged: EventEmitter<number> = new EventEmitter();

  @ViewChild('leftOption') leftOption!: ElementRef<HTMLElement>;
  @ViewChild('rightOption') rightOption!: ElementRef<HTMLElement>;

  private readonly borderWidth = 4;
  private readonly spacerWidth = 12;

  leftOptionWidth: number | undefined;
  rightOptionWidth: number | undefined;
  leftOptionLeft: number | undefined;
  rightOptionLeft: number | undefined;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.leftOptionWidth = this.leftOption.nativeElement.getBoundingClientRect().width - this.borderWidth;
    this.rightOptionWidth = this.rightOption.nativeElement.getBoundingClientRect().width - this.borderWidth;
    this.leftOptionLeft = this.borderWidth;
    this.rightOptionLeft = this.leftOptionWidth + this.spacerWidth + this.borderWidth;
    this.changeDetectorRef.detectChanges();
  }

  onOptionClicked(index: number): void {
    this.activeIndexChanged.emit(index);
  }
}
