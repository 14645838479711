import { Action, createReducer, on } from '@ngrx/store';
import { UserScopeViewModel } from '../../main/viewModels/userScope.viewModel';
import * as UserScopesActions from '../actions/userScopes.actions';

export interface UserScopesState {
  userScopes: UserScopeViewModel[];
  appliedUserScope: UserScopeViewModel;
  articleUserScopes: UserScopeViewModel[];
  appliedArticleUserScope: UserScopeViewModel;
}

export const initialState: UserScopesState = {
  userScopes: null,
  appliedUserScope: null,
  articleUserScopes: null,
  appliedArticleUserScope: null,
};

export const userScopesReducer = createReducer<UserScopesState>(
  initialState,
  on(
    UserScopesActions.loadUserScopesSuccessAction,
    (state: UserScopesState, { payload: { userScopes } }: { payload: { userScopes: UserScopeViewModel[] } }) => {
      return {
        ...state,
        userScopes,
      };
    }
  ),
  on(
    UserScopesActions.setAppliedUserScopeAction,
    (
      state: UserScopesState,
      { payload: { appliedUserScope } }: { payload: { appliedUserScope: UserScopeViewModel } }
    ) => {
      return {
        ...state,
        appliedUserScope,
      };
    }
  ),
  on(
    UserScopesActions.setArticleUserScopes,
    (
      state: UserScopesState,
      { payload: { articleUserScopes } }: { payload: { articleUserScopes: UserScopeViewModel[] } }
    ) => {
      return {
        ...state,
        articleUserScopes,
      };
    }
  )
);

export function UserScopesReducer(state: UserScopesState | undefined, action: Action): UserScopesState {
  return userScopesReducer(state, action);
}

export const getUserScopes = (state: UserScopesState) => state.userScopes;
export const getAppliedUserScope = (state: UserScopesState) => state.appliedUserScope;
export const getArticleUserScopes = (state: UserScopesState) => state.articleUserScopes;
