import { Action, createReducer, on } from '@ngrx/store';
import { ArticleViewModel } from '../../main/viewModels/article.viewModel';
import * as ArticlesActions from '../actions/articles.actions';

export interface ArticlesState {
  articles: ArticleViewModel[];
  articlesSearchTerm: string;
}

export const initialState: ArticlesState = {
  articles: null,
  articlesSearchTerm: null,
};

export const articlesReducer = createReducer<ArticlesState>(
  initialState,
  on(ArticlesActions.loadArticlesAction, (state: ArticlesState) => {
    return {
      ...state,
      articles: null,
    };
  }),
  on(
    ArticlesActions.loadArticlesSuccessAction,
    (state: ArticlesState, { payload: { articles } }: { payload: { articles: ArticleViewModel[] } }) => {
      return {
        ...state,
        articles,
      };
    }
  ),
  on(
    ArticlesActions.setArticlesSearchTermAction,
    (state: ArticlesState, { payload: { articlesSearchTerm } }: { payload: { articlesSearchTerm: string } }) => {
      return {
        ...state,
        articlesSearchTerm,
      };
    }
  )
);

export function ArticlesReducer(state: ArticlesState | undefined, action: Action): ArticlesState {
  return articlesReducer(state, action);
}

export const getArticles = (state: ArticlesState) => state.articles;
export const getArticlesSearchTerm = (state: ArticlesState) => state.articlesSearchTerm;
