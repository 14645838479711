import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthFacade} from '../../store/facades/auth.facade';
import {LoginResponseDTO} from "../../auth/dto/loginResponse.dto";
import {environment} from '../../../environments/environment';


const TOKEN_KEY = 'TOKEN_KEY';
const USER_KEY = 'USER_KEY';
const USER_SCOPE_KEY = 'USER_SCOPE_KEY';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authFacade: AuthFacade
  ) {
  }

  canActivate(): Observable<boolean> {

    if (this.hasSsoAuthResponse()) {
      let ssoAuthResponse = this.getSsoAuthResponse();
      sessionStorage.setItem(TOKEN_KEY, ssoAuthResponse.data.token);
      sessionStorage.setItem(USER_KEY, JSON.stringify(ssoAuthResponse.data.user));
      sessionStorage.setItem(USER_SCOPE_KEY, JSON.stringify(ssoAuthResponse.data.userScope));
    }

    return this.authFacade.token$.pipe(
      take(1),
      map((token: string) => {
        const tokenExists: boolean = Boolean(token);

        if (!tokenExists) {
          window.location.href = `${environment.host}/auth`;
        }
        return tokenExists;
      })
    );
  }

  hasSsoAuthResponse() {
    const queryStrings = this.getQueryStrings();
    return queryStrings && queryStrings.ssoAuthResponse;
  }

  getSsoAuthResponse(): LoginResponseDTO {
    const queryStrings = this.getQueryStrings();
    return JSON.parse(queryStrings.ssoAuthResponse);
  }

  getQueryStrings() {
    const assoc = {
      ssoAuthResponse: ""
    };
    const queryString = location.search.substring(1);
    const keyValues = queryString.split('&');

    for (const i in keyValues) {
      const key = keyValues[i].split('=');
      if (key.length > 1) {
        assoc[this.decodeQueryString(key[0])] = this.decodeQueryString(key[1]);
      }
    }
    return assoc;
  }

  decodeQueryString(s) {
    return decodeURIComponent(s.replace(/\+/g, " "));
  };

}
