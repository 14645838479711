import { Action, createReducer, on } from '@ngrx/store';
import { LoginResponseDTO } from '../../auth/dto/loginResponse.dto';
import { UserViewModel } from '../../shared/viewModels/user.viewModel';
import * as AuthActions from '../actions/auth.actions';
import {UserScopeViewModel} from "../../main/viewModels/userScope.viewModel";

export interface AuthState {
  token: string;
  user: UserViewModel;
  userScope: UserScopeViewModel;
}

const TOKEN_KEY = 'TOKEN_KEY';
const USER_KEY = 'USER_KEY';
const USER_SCOPE_KEY = 'USER_SCOPE_KEY';

export const initialState: AuthState = {
  token: sessionStorage.getItem(TOKEN_KEY),
  user: JSON.parse(sessionStorage.getItem(USER_KEY)),
  userScope: JSON.parse(sessionStorage.getItem(USER_SCOPE_KEY)),
};

export const authReducer = createReducer<AuthState>(
  initialState,
  on(AuthActions.loginSuccessAction, (state: AuthState, { payload }: { payload: LoginResponseDTO }) => {
    sessionStorage.setItem(TOKEN_KEY, payload.data.token);
    sessionStorage.setItem(USER_KEY, JSON.stringify(payload.data.user));
    sessionStorage.setItem(USER_SCOPE_KEY, JSON.stringify(payload.data.userScope));

    return {
      ...state,
      ...payload.data,
    };
  }),
  on(AuthActions.logoutAction, (state: AuthState) => {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem(USER_KEY);
    sessionStorage.removeItem(USER_SCOPE_KEY);

    return {
      ...state,
      token: null,
      user: null,
      userScope: null,
    };
  })
);

export function AuthReducer(state: AuthState | undefined, action: Action): AuthState {
  return authReducer(state, action);
}

export const getToken = (state: AuthState) => state.token;
export const getUser = (state: AuthState) => state.user;
export const getUserScope = (state: AuthState) => state.userScope;

