import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginRequestDTO } from '../dto/loginRequest.dto';
import { LoginResponseDTO } from '../dto/loginResponse.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly httpClient: HttpClient) {}

  login(loginRequestDTO: LoginRequestDTO): Observable<LoginResponseDTO> {
    return this.httpClient.post<LoginResponseDTO>(`/admin/login`, loginRequestDTO);
  }
}
