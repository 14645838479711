import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { UserScopesService } from '../../main/services/user-scopes.service';
import { UserScopeViewModel } from '../../main/viewModels/userScope.viewModel';
import * as UserScopesActions from '../actions/userScopes.actions';

@Injectable({
  providedIn: 'root',
})
export class UserScopesEffects {
  loadUserScopes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserScopesActions.loadUserScopesAction),
      concatMap(() =>
        this.userScopesService.getUserScopes().pipe(
          map((userScopes: UserScopeViewModel[]) => {
            return UserScopesActions.loadUserScopesSuccessAction({
              payload: { userScopes },
            });
          }),
          catchError((error: Error) =>
            of(
              UserScopesActions.loadUserScopesErrorAction({
                payload: { error: error.message },
              })
            )
          )
        )
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly userScopesService: UserScopesService) {}
}
