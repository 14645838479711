import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserScopeViewModel } from '../../main/viewModels/userScope.viewModel';
import * as UserScopesActions from '../actions/userScopes.actions';
import * as fromRoot from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class UserScopesFacade {
  public readonly userScopes$ = this.store.select(fromRoot.getUserScopes);
  public readonly appliedUserScope$ = this.store.select(fromRoot.getAppliedUserScope);
  public readonly articleUserScopes$ = this.store.select(fromRoot.getArticleUserScopes);

  constructor(private store: Store<fromRoot.State>) {}

  public loadUserScopes(): void {
    this.store.dispatch(UserScopesActions.loadUserScopesAction());
  }

  public setAppliedUserScope(appliedUserScope: UserScopeViewModel): void {
    this.store.dispatch(
      UserScopesActions.setAppliedUserScopeAction({
        payload: { appliedUserScope },
      })
    );
  }

  public setArticleUserScopes(articleUserScopes: UserScopeViewModel[]): void {
    this.store.dispatch(UserScopesActions.setArticleUserScopes({ payload: { articleUserScopes } }));
  }
}
