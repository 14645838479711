import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateArticleRequestDTO } from '../dto/createArticleRequest.dto';
import { ArticleViewModel } from '../viewModels/article.viewModel';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  constructor(private readonly httpClient: HttpClient) {}

  getArticles(scopeIds: number[], searchTerm: string): Observable<ArticleViewModel[]> {
    return this.httpClient.get<ArticleViewModel[]>(`/search`, {
      params: new HttpParams().append('scopeIds', `[${scopeIds.join(',')}]`).append('keywords', searchTerm),
    });
  }

  getArticle(articleId: number, scopeIds: number[]): Observable<ArticleViewModel> {
    return this.httpClient.get<ArticleViewModel>(`/articles-scope`, {
      params: new HttpParams().append('articleId', articleId.toString()).append('scopeIds', `[${scopeIds.join(',')}]`),
    });
  }

  createArticle(createArticleRequestDTO: CreateArticleRequestDTO): Observable<ArticleViewModel> {
    return this.httpClient.post<ArticleViewModel>(`/articles/create`, createArticleRequestDTO);
  }

  updateArticle(
    id: number,
    body: string,
    user_scope: number,
    title: string,
    title_aliases: string[] | string,
    scopeIds: number[]
  ): Observable<ArticleViewModel> {
    const tags: string[] = typeof title_aliases === 'string' ? title_aliases.split(';') : title_aliases;

    return this.httpClient.put<ArticleViewModel>(
      `/articles/update/${id}`,
      {
        body,
        user_scope,
        title,
        title_aliases: tags
      },
      {
        params: new HttpParams().append('scopeIds', `[${scopeIds.join(',')}]`),
      }
    );
  }

  linkArticle(articleId: number, scopeIds: number[]): Observable<ArticleViewModel> {
    return this.httpClient.put<ArticleViewModel>(`/articles/link`, null, {
      params: new HttpParams().append('articleId', articleId.toString()).append('scopeIds', `[${scopeIds.join(',')}]`),
    });
  }

  getLandingArticle(): Observable<ArticleViewModel> {
    return this.httpClient.get<ArticleViewModel>(`/getLandingArticle`);
  }

  getArticleTags(): Observable<string[]> {
    return this.httpClient.get<string[]>(`/article-tags`);
  }
}
