import { createAction, props } from '@ngrx/store';
import { LoginRequestDTO } from '../../auth/dto/loginRequest.dto';
import { LoginResponseDTO } from '../../auth/dto/loginResponse.dto';

export const LOGIN = '[AUTH] LOGIN';
export const LOGIN_SUCCESS = '[AUTH] LOGIN_SUCCESS';
export const LOGIN_ERROR = '[AUTH] LOGIN_ERROR';

export const loginAction = createAction(LOGIN, props<{ payload: LoginRequestDTO }>());
export const loginSuccessAction = createAction(LOGIN_SUCCESS, props<{ payload: LoginResponseDTO }>());
export const loginErrorAction = createAction(LOGIN_ERROR, props<{ payload: { error: string } }>());

export const LOGOUT = '[AUTH] LOGOUT';

export const logoutAction = createAction(LOGOUT);
