import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoginRequestDTO } from '../../auth/dto/loginRequest.dto';
import * as AuthActions from '../actions/auth.actions';
import * as fromRoot from '../reducers';
import {LoginResponseDTO} from "../../auth/dto/loginResponse.dto";

@Injectable({
  providedIn: 'root',
})
export class AuthFacade {
  public readonly token$ = this.store.select(fromRoot.getToken);
  public readonly user$ = this.store.select(fromRoot.getUser);
  public readonly userScope$ = this.store.select(fromRoot.getUserScope);

  constructor(private store: Store<fromRoot.State>) {}

  public login(loginRequestDTO: LoginRequestDTO): void {
    this.store.dispatch(AuthActions.loginAction({ payload: loginRequestDTO }));
  }

  public logout(): void {
    this.store.dispatch(AuthActions.logoutAction());
  }

  public success(loginResponseDTO: LoginResponseDTO) {
    this.store.dispatch(AuthActions.loginSuccessAction({ payload: loginResponseDTO }))
  }
}
