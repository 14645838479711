import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { ExceptionInterceptor } from './exception.interceptor';
import { JwtInterceptor } from './jwt.interceptor';
import { ProxyInterceptor } from './proxy.interceptor';

export const interceptors: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ProxyInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ExceptionInterceptor,
    multi: true,
  },
];
